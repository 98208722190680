/* Landing page */

#landing {
  background-color: white;
  // background: url("/images/landing-bg2.jpg");
  // background: url("/images/landing-bg2.jpg"), linear-gradient(to bottom, #282a31 0%,#282a31 77%,#181a1e 100%);
  background-position-x: 50%;
  background-position-y: 50%;
  min-height: 100vh;
  background-size: cover;
  background-attachment: fixed;

  .logo {
    margin: 40px auto;
    height: 60px; 
  
  }

  .tab-content {
    
    padding: 20px;
    background-color: white;
    border-bottom: 0;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    margin-bottom: 20px;
    border: lightgray;
    border-style: solid;
    border-width: 2px;
  }

  .main {
    border-radius: 20px;
  }

  h1{
    color: #353e5a;
    font-size: 30px;
    font-weight: 300;
    letter-spacing: 1px;
    margin-bottom: 35px;
  }
}
